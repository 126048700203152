import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IQueryParams } from '@locumsnest/core/src';
import { buildQueryParams } from '@locumsnest/core/src/lib/helpers/util';
import { HttpEndpointService } from '@locumsnest/core/src/lib/http/http-endpoint.service';

import { LocumsNestEndpointConfig } from '../../constants';

@Injectable()
export class FileDownloaderService {
  constructor(
    private httpEndpointService: HttpEndpointService<LocumsNestEndpointConfig>,
    private http: HttpClient,
  ) {}

  public download(endpointKey: keyof LocumsNestEndpointConfig, queryParams: IQueryParams) {
    let url = this.getFullEndpoint(endpointKey);
    url += this.addQueryParams(queryParams);
    window.location.href = url;
  }

  public downloadFromUrl(fileUrlPath: string) {
    return this.http.get(fileUrlPath, {
      responseType: 'blob',
    });
  }

  public downloadFile(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  private getFullEndpoint(endpointKey: keyof LocumsNestEndpointConfig) {
    return this.httpEndpointService.getEndpoint(endpointKey);
  }

  private addQueryParams(queryParams: IQueryParams) {
    return buildQueryParams(queryParams);
  }
}
